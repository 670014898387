import { AppProps } from 'next/app'
import { v4 } from 'uuid'

import { createContext, useContext, useEffect, useState } from 'react'

import '../styles/global.css'
import Head from 'next/head'
import Script from 'next/script'
import { useCookie } from '@/utils/Session'
import { init, note } from '@/utils/Note'

// Setup open sans google font
import { Open_Sans } from 'next/font/google'

const openSans = Open_Sans({  
  subsets: ['latin'],
})

/**
 * Create a global context for session data
 */
export type ISessionContext = [string | undefined, (email: string) => void]
const sessionContext = createContext<ISessionContext | null>(null)

const SessionContext = ({ children }: any) => {
  const [sessionEmail, setSessionEmail] = useState<string>()
  return <sessionContext.Provider value={[sessionEmail, setSessionEmail]}>{children}</sessionContext.Provider>
}

export function useSessionContext() {
  return useContext(sessionContext)
}

/**
 * Render the app
 * @param param0 
 * @returns 
 */
const SyncApp = ({ Component, pageProps }: AppProps) => {
  const [userGuid, setUserGuid] = useCookie('userGuid')

  // Create an arbitrary user guid so that I can determine 
  // if it's me or someone else when viewing the analytics
  useEffect(() => {
    const g = userGuid()
    if (!g) {
      setUserGuid(v4().toString())
    }

    init()
  }, [])

  return (
    <SessionContext>
      <Head>
        <script async src="https://analytics.umami.is/script.js" data-website-id="604919fa-8d50-4805-84cc-d52b97889090"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6FLYFP8YTD"></script>
      </Head>
      <Script id="ms clarity">
        {`
        (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "i812wqwb22");
        `}
      </Script>
      <Script id="ga4"> 
      { 
        // process.env.VERCEL_ENV === 'production' && 
      `
        <!-- Google tag (gtag.js) -->
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-6FLYFP8YTD');
      `}
      </Script>
      <Script id="meta-pixel">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1259001525517323');
          fbq('track', 'PageView');
        `}


      </Script>
      <div className={openSans.className}>
        <Component {...pageProps} />
      </div>
    </SessionContext>
  )
}

export default SyncApp
